import React from "react"
import "../style/contact.css"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

const contactPage = () => {
    return (
        <Layout>
            <br /><br /><br /><br />
            <div className="container pl-5 pr-5 pt-5 pb-5">
                <div className="row ">
                    <div className="col-md-4 pb-3">
                        <p className="c-p"><b>CORPORATE OFFICE</b></p>
                        <p className="c-p">Address : Sy.No.108/2, Millenia building,1st Main, MSR Layout, Munnekollala Village, Marathahalli Outer Ring Road, Bangalore 560037</p>
                    </div>
                    <div className="col-md-4 pb-3">
                        <p className="c-p"><b>GET IN TOUCH</b></p>
                        <p className="c-p"><a href="tel:+91 8431 999 444" className="c-p">Phone : +91 8431 999 444</a>
                            <a href="mailto:enquiries@realvaluehomes.in" className="c-p">Email : enquiries@realvaluehomes.in</a>
                            <a href="https://sumadhuragroup.com" target="_blank" className="c-p">Website : www.sumadhuragroup.com/</a></p> </div>
                    <div className="col-md-4 pb-3">
                        <p className="c-p"><b>CONNECT WITH US</b></p>
                        <a href="https://www.facebook.com/Aspire-Series-A-Sumadhura-Venture-106528511410714" target="_blank">
                            <span className="c-social">
                                <i className="fa fa-facebook-official" aria-hidden="true"></i>
                            </span></a>
                        <a href="https://www.instagram.com/aspire__series/" target="_blank">
                            <span className="c-social">
                                <i className="fa fa-instagram" aria-hidden="true"></i>
                            </span></a>
                        <a href="https://www.youtube.com/c/Sumadhuragroupbuilders " target="_blank">
                                <span className="c-social">
                                <i className="fa fa-youtube-play" aria-hidden="true"></i>
                            </span></a>
                        <a href="https://twitter.com/teamsumadhura " target="_blank">
                                <span className="c-social">
                                <i className="fa fa-twitter" aria-hidden="true"></i>
                            </span></a>
                        <a href="https://www.linkedin.com/company/sumadhura-infracon-pvt--ltd " target="_blank">
                                <span className="c-social">
                                <i className="fa fa-linkedin" aria-hidden="true"></i>
                            </span></a>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row pb-5">
                    <div className="col-md-6 bg-aspireblue pb-4 pt-4">
                        <h5 className="text-center text-white">SEND US A MESSAGE</h5><br />
                        <input type="text" placeholder="Name" className="form-control" id="contact_name" /><br />
                        <input type="text" placeholder="Email" className="form-control" id="contact_email" /><br />
                        <input type="number" placeholder="Phone" className="form-control" id="contact_phone" /><br />
                        <select className="form-control" id="contact_source">
                            <option selected disabled>How did you know about us?</option>
                            <option>Hoarding</option>
                            <option>Web Ads</option>
                            <option>Facebook</option>
                            <option>E-mails</option>
                            <option>SMS</option>
                            <option>Newspapers</option>
                            <option>T.V. Ads</option>
                        </select><br />
                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="4" placeholder="Message" id="contact_message"></textarea><br />
                        <button className="btn btn-dark float-right" style={{ padding: "10px 30px" }} id="contact_submit_btn">Submit</button>
                    </div>
                    <div className="col-md-6">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.3167220116206!2d77.7001124152698!3d12.951573818820643!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae13cc6b22b8ff%3A0x5dab8ccc47f47a83!2sSumadhura%20Infracon%20Private%20Limited!5e0!3m2!1sen!2sin!4v1599658404980!5m2!1sen!2sin" width="100%" height="550" frameborder="0" style={{ border: "0" }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                    </div>
                </div>
                
            </div>
            <script src="../Scripts/contact_mail.js"></script>
	    <script src="https://www.kenyt.ai/botapp/ChatbotUI/dist/js/bot-loader.js" type="text/javascript" data-bot="25390341"></script>
        </Layout>
    )
}
export default contactPage
